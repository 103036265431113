<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
             <SideMenu :indexsec="11"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
           
            <form
              @submit.prevent="
                submit(
                 data.name,
                 file1,
                )
              "
            >
            <div class="div_EProduct">
              <p>EDITAR LIGA</p>
              </div>

              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="data.name"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>

                <div class="div-image QUITLEFT">
                  <label class="labelImg color-1" for="inputImg">Logo</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div>

                 
                
              </div>

              


           
               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>


              

              

              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">La liga se edito correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="agregarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
              
              
              
                 <div id="edit-user-img" class="Imagen_EditSpons" v-if="msgimg == 'success'">
                 <img :src="this.image(data.image)"  alt="imagen de usuario">
                 </div>
            
              
            
            
           


  
          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import Header from '../../components/Header';
import { mapActions } from "vuex";

export default {
  name: "EditGallery",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      status: "",
      msg: "",
      msgimg: "success",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,

    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
    let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "liga",
    };
    this.getInfoById(payload);
    
  },
  computed:{
      data() {
      return this.$store.getters["main/data"];
    },
     url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {            
        return this.$store.getters['admin/getIdentity'];
    },
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (  name, image) {
          let id = this.$route.params.id;
          
          this.status=''
          this.msg=''

    

         if(image == null){
                 let data ={
                     id:id,
                     name:name,
                 }
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/editItem",  {option:'liga', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.showModal() 
                  }
           
        }else{

          if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
                var data = new  FormData();
              
                  data.append('id',id);
                  data.append('image', image);
                  data.append('name',name);
                  data.append('_method', 'PUT');
                
                      
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/editItem",  {option:'liga', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.showModal() 
                  }

            }
                

        }

    },
    agregarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
  image: function(img){
        let src = this.url +'/get-liga-image/'+img;
         this.status_img(img)
        return src
         },
    status_img:async  function (img){
    let userimg =''
      try{
          userimg = await this.$store.dispatch("main/getImage",{image: img, option:'liga'})
          if(userimg.data.status=='error'){
              this.msgimg='error'
          }else{
              this.msgimg='success'
          }
      }catch(err){
          this.msgimg='error'
      }
    },

  },
};
</script>
